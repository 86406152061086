import { Component, OnInit, Input } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { ApiClientService } from '../api-client.service';

@Component({
  selector: 'app-book',
  templateUrl: './book.component.html',
  styleUrls: ['./book.component.scss']
})
export class BookComponent implements OnInit {
	@Input('title') title: string;
	@Input('url') url: any;
	@Input('bookId') bookId: string;

	fileUrl: any ;


  	constructor(
		private apiClientService: ApiClientService,
		private sanitizer: DomSanitizer
	) { }

	ngOnInit() {

		// this.title= this.title.replace(/-/g, ' ');
		// console.log("title :",this.title);
		
  	}


	  showPDF() {
		console.log(this.url)

		window.open("assets/"+this.title+".pdf")
		//   .then(blob => {
		// 	this.fileUrl = this.sanitizer.bypassSecurityTrustResourceUrl(URL.createObjectURL(blob));
		// 	//window.open(this.fileUrl);
		//   });
	  }

	//   sanitizer(){

	// 		this.fileUrl = this.sanitizer.bypassSecurityTrustResourceUrl(URL.createObjectURL(blob));
	// 	  });
	  
	

}
