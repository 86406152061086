import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AppComponent } from './app.component';
import { SearchBooksComponent } from './search-books/search-books.component';


const routes: Routes = [
	{ 
	    path: 'home',
	    component: SearchBooksComponent
	},
	{ 
	    path: '',
	    component: SearchBooksComponent
	},
	{ 
	    path: '**', 
	    redirectTo: 'home'
	}
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
