import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from '../environments/environment';

@Injectable()
export class ApiClientService {

  constructor(
    private http: HttpClient
  ) { }

  getPDF(url) {
    //= environment.PDF_URL;
    const options = {  
      headers: new HttpHeaders({
      'Content-Type': 'application/json'
      }),
      observe: 'response' as 'body',
      responseType: 'blob' as 'blob'};

    return this.http.get(url ,options);
  }

}
