import { Component, OnInit } from '@angular/core';
import allData from '../../assets/data.json';
import genderData from '../../assets/gender.json';
import commissionData from '../../assets/commission.json';
import readingsData from '../../assets/readings.json';

@Component({
  selector: 'app-search-books',
  templateUrl: './search-books.component.html',
  styleUrls: ['./search-books.component.css']
})
export class SearchBooksComponent implements OnInit {

	Books: any = allData;
	searchText:string;
	displayText="All Document";

  	constructor() {}

  	ngOnInit() {
  	}


	change(e){

     let topic = e.target.id

     switch (topic) {
		case "all":
			this.Books= allData
			this.displayText="All Document"
			break;

			case "gender":
			this.Books= genderData
			this.displayText="Gender Inclusion & IA"
			break;
	 
			case "commission":
				this.Books= commissionData
				this.displayText="EU Commission - New AI Regulation"
			break;
	 
			case "readings":
				this.Books= readingsData
				this.displayText="HealthDARES readings"
			break;
	 
	 
		default:
			break;
	 }
	}

}
